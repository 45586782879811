import React from 'react';
import AssetZenImage from '../AssetZenImage/AssetZenImage';
import styles from './TeamMember.module.scss';

const TeamMember = ({ imgId, name, jobTitle, desc }) => {
    return (
        <article className={styles.teamMember}>
            <AssetZenImage className={styles.img} id={imgId} width="345" height="433"/>
            <div className={styles.inner}>
                <header className={styles.header}>
                    <h3 className={styles.name}>{name}</h3>
                    <strong className={styles.jobTitle}>{jobTitle}</strong>
                </header>
                <p className={styles.desc}>{desc}</p>
            </div>
        </article>
    )
}

TeamMember.propTypes = {

};

export default TeamMember;
