import React from 'react';
import AssetZenImage from '../AssetZenImage/AssetZenImage';
import styles from './Banner.module.scss';

const Banner = ({imgId, caption}) => {
    return (
        <figure className={styles.banner}>
            <AssetZenImage className={styles.img} id={imgId} width="1600" height="948" style={{ position: 'absolute'}}/>
            <footer className={styles.caption}>
                {caption}
            </footer>
        </figure>
    )
}

Banner.propTypes = {

};

export default Banner;
