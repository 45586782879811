import React from 'react';
import AssetZenImage from '../AssetZenImage/AssetZenImage';
import styles from './Director.module.scss';

const Director = ({ imgId, name, jobTitle, desc }) => {
    return (
        <article className={styles.director}>
            <AssetZenImage className={styles.img} id={imgId} width="460" height="433"/>
            <header className={styles.header}>
                <h3 className={styles.name}>{name}</h3>
                <strong className={styles.jobTitle}>{jobTitle}</strong>
            </header>
            <p className={styles.desc}>{desc}</p>
        </article>
    )
}

Director.propTypes = {

};

export default Director;
