import React from 'react';
import { graphql } from 'gatsby';
import { v4 } from 'uuid';
import { themes } from "../context/ThemeContext";
import Layout from '../components/Layout';
import SectionRenderer from '../components/SectionRenderer/SectionRenderer';
import Section from '../components/Section/Section';
import Hgroup from '../components/UI/Hgroup/Hgroup';
import Content, { HTMLContent } from '../components/Content';
import Cover from '../components/Cover/Cover';
import { PanelGroup } from '../components/UI/Panel/Panel';
import Article from '../components/Article/Article';
import Director from '../components/UI/Director/Director';
import TeamMember from '../components/UI/TeamMember/TeamMember';
import Banner from '../components/UI/Banner/Banner';
import { Grid, GridItem } from '../components/UI/Grid/Grid';
import Fade from 'react-reveal/Fade';
import parseMarkdown from '../utils/parseMarkdown';
import type from '../styles/type.module.scss';

export const AboutPageTemplate = ({
    name,
    title,
    intro,
    image,
    banner,
    html,
    directors,
    team,
    sections,
    contentComponent
}) => {
    const PageContent = contentComponent || Content;

    return (
        <>
            <Cover isPrimary={true} image={image}>
                <Fade bottom>
                    <Hgroup lead={name} title={title} isReverse={true} isPrimary={true}/>
                    { intro &&
                        
                        <Grid>
                            <GridItem lg={6}>
                                <p><strong>{intro}</strong></p>
                            </GridItem>
                        </Grid>
                        
                    }
                </Fade>
            </Cover>

            {banner &&
                <Section theme={themes.DARK} isUnstyled={true}>
                    <Banner {...banner} />
                </Section>
            }
            
            <Section theme={themes.LIGHT} isCompact={true} containerStyle="SECONDARY">
                <Article>
                    <Fade bottom>
                    {html &&
                        <PageContent content={html} />
                    }
                    </Fade>
                </Article>
            </Section>
            
            <Section theme={themes.LIGHT} isCompact={true}>
                <Fade bottom>
                    <h2 className={type.h2}>Our directors</h2>
                </Fade>

                <PanelGroup cols={{ md: 4 }} useSliderBelowBreakpoint={true}>
                    {directors.map(director =>
                        <Director {...director} key={director.name}/>
                    )}
                </PanelGroup>
            </Section>

            <Section theme={themes.LIGHT} containerStyle="SECONDARY" isCompact={true}>
                <Fade bottom>
                    <h2>Meet the core team</h2>
                    <p className={type.lead}><strong>Our team is packed full of experienced professionals who specialize in development, design, SEO and online marketing.</strong></p>
                </Fade>

                <PanelGroup cols={{ md: 4, lg: 3 }} useSliderBelowBreakpoint={true}>
                    {team.map(member =>
                        <TeamMember {...member} key={v4()}/>
                    )}
                </PanelGroup>
            </Section>

            <SectionRenderer data={parseMarkdown(sections)} />
        </>
    )
};

const AboutPage = ({ data: { page } }) => {
    return (
        <Layout 
            meta={page.frontmatter.meta || false}
            title={page.frontmatter.name || false}
            theme={themes.DARK}>
            <AboutPageTemplate {...parseMarkdown(page)}
            contentComponent={HTMLContent} />
        </Layout>
    )
};

export default AboutPage;

export const pageQuery = graphql`
  ## Query for AboutPage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query AboutPageTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Sections
      frontmatter {
        name
        title
        intro
        image {
            id
            src {
                publicURL
                extension
            }
            position
            fit
        }
        banner {
          imgId
          caption
        }
        directors {
          name
          jobTitle
          desc
          imgId
        }
        team {
          name
          jobTitle
          desc
          imgId
        }
      }
      html
    }
  }
`
